var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "school-follow" },
    [
      _c(
        "div",
        { staticStyle: { width: "94%", margin: "0 auto" } },
        [
          _c("Search", {
            attrs: {
              searchAllParam: _vm.searchAllParam,
              searchParamList: _vm.searchParamList,
              code: "count"
            },
            on: { search: _vm.search, reset: _vm.reset }
          })
        ],
        1
      ),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "target",
          staticStyle: { width: "94%", margin: "0 auto" }
        },
        [
          _c("span", [_vm._v("当月新量核心指标")]),
          _c(
            "el-row",
            _vm._l(_vm.targetList, function(item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  style: { background: item.color },
                  attrs: { span: 6 }
                },
                [
                  _c("h4", [_vm._v(_vm._s(item.value))]),
                  _c("span", [_vm._v(_vm._s(item.name))])
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "line" }),
      _c("div", { staticStyle: { width: "94%", margin: "0 auto" } }, [
        _c("span", [_vm._v("当月客户配量分析")]),
        _c("div", {
          ref: "analyseChart",
          staticStyle: { width: "100%", height: "300px" }
        })
      ]),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        { staticStyle: { width: "94%", margin: "0 auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", [_vm._v("获客渠道占比分析")]),
                _c("div", {
                  ref: "hkChart",
                  staticStyle: { width: "100%", height: "300px" }
                })
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", [_vm._v("成交客户-渠道分布占比分析")]),
                _c("div", {
                  ref: "dealChart",
                  staticStyle: { width: "100%", height: "300px" }
                })
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", [_vm._v("未成交客户-意向状态分析")]),
                _c("div", {
                  ref: "yxChart",
                  staticStyle: { width: "100%", height: "300px" }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "line" }),
      _c("div", { staticStyle: { width: "94%", margin: "0 auto" } }, [
        _c("span", [_vm._v("各渠道转化占比分析")]),
        _c("div", {
          ref: "zhChart",
          staticStyle: { width: "100%", "min-height": "300px" }
        })
      ]),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        { staticStyle: { width: "94%", margin: "0 auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 16 } }, [
                _c("span", [_vm._v("成交客户跟进次数分析")]),
                _c("div", {
                  ref: "followChart",
                  staticStyle: { width: "100%", height: "300px" }
                })
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", [_vm._v("销售-成交客户分析")]),
                _c("div", {
                  ref: "saleChart",
                  staticStyle: { width: "100%", height: "300px" }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        { staticStyle: { width: "94%", margin: "0 auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", [_vm._v("跟进记录统计")]),
                _c("div", {
                  ref: "recordChart",
                  staticStyle: { width: "100%", height: "300px" }
                })
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", [_vm._v("外呼统计分析")]),
                _c("div", {
                  ref: "callChart",
                  staticStyle: { width: "100%", height: "300px" }
                })
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", [_vm._v("通时统计")]),
                _c("div", {
                  ref: "timeChart",
                  staticStyle: { width: "100%", height: "300px" }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("ModalTable", {
        ref: "table",
        attrs: { columns: _vm.columns, searchParam: _vm.searchParam }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }