<!--
 * @Description: 校区客户跟进仪表盘
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-05-25 18:03:22
 * @LastEditors: Seven
 * @LastEditTime: 2021-10-20 17:41:03
-->
<template>
  <div class="school-follow">
    <div style="width: 94%; margin: 0 auto">
      <Search
        :searchAllParam="searchAllParam"
        @search="search"
        @reset="reset"
        :searchParamList="searchParamList"
        code="count"
        />
    </div>
    <div class="line"></div>
    <!-- 当月新量核心指标 -->
    <div class="target" style="width: 94%; margin: 0 auto" v-loading="loading">
      <span>当月新量核心指标</span>
      <el-row>
        <el-col :style="{background: item.color}" v-for="(item, index) in targetList" :span="6" :key="index">
          <h4>{{ item.value }}</h4>
          <span>{{ item.name }}</span>
        </el-col>
      </el-row>
    </div>
    <div class="line"></div>
    <!-- 当月客户配量 分析 -->
    <div style="width: 94%; margin: 0 auto">
      <span>当月客户配量分析</span>
      <div ref="analyseChart" style="width: 100%;height: 300px"></div>
    </div>
    <div class="line"></div>
    <div style="width: 94%; margin: 0 auto">
      <el-row :gutter="24">
        <el-col :span="8">
          <span>获客渠道占比分析</span>
          <div ref="hkChart" style="width: 100%;height: 300px"></div>
        </el-col>
        <el-col :span="8">
          <span>成交客户-渠道分布占比分析</span>
          <div ref="dealChart" style="width: 100%;height: 300px"></div>
        </el-col>
        <el-col :span="8">
          <span>未成交客户-意向状态分析</span>
          <div ref="yxChart" style="width: 100%;height: 300px"></div>
        </el-col>
      </el-row>
    </div>
    <div class="line"></div>
    <div style="width: 94%; margin: 0 auto">
      <span>各渠道转化占比分析</span>
      <div ref="zhChart" style="width: 100%;min-height: 300px"></div>
    </div>
    <div class="line"></div>
    <div style="width: 94%; margin: 0 auto">
      <el-row :gutter="24">
        <el-col :span="16">
          <span>成交客户跟进次数分析</span>
          <div ref="followChart" style="width: 100%;height: 300px"></div>
        </el-col>
        <el-col :span="8">
          <span>销售-成交客户分析</span>
          <div ref="saleChart" style="width: 100%;height: 300px"></div>
        </el-col>
      </el-row>
    </div>
    <div class="line"></div>
    <div style="width: 94%; margin: 0 auto">
      <el-row :gutter="24">
        <el-col :span="8">
          <span>跟进记录统计</span>
          <div ref="recordChart" style="width: 100%;height: 300px"></div>
        </el-col>
        <el-col :span="8">
          <span>外呼统计分析</span>
          <div ref="callChart" style="width: 100%;height: 300px"></div>
        </el-col>
        <el-col :span="8">
          <span>通时统计</span>
          <div ref="timeChart" style="width: 100%;height: 300px"></div>
        </el-col>
      </el-row>
    </div>
    <ModalTable 
      ref="table"
      :columns="columns" 
      :searchParam="searchParam" />
  </div>
</template>
<script>
import Search from "components/search";
import * as echarts from 'echarts';
import { getQuerySchoolFollowReport, getQuerySchoolList } from "api/count";

const option = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      show: true,
      textStyle: {
        color: '#666',
      },
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value}'
    },
    axisPointer: {
      snap: true
    },
    axisLine: {
      // y轴
      show: true,
    },
  },
  legend: {
    left: 'center',
    bottom: 20,
    itemGap: 100,
    data: [
      {
        name: '新增客户',
        icon: 'roundRect',
        textStyle: {
          color: '',
        },
      },
      {
        name: '有效量',
        icon: 'rect',
        textStyle: {
          color: '',
        },
      },
      {
        name: '成交量',
        icon: 'roundRect',
        textStyle: {
          color: '',
        },
      },
    ],
  },
  series: [
    {
      name: '新增客户',
      type: 'line',
      data: [],
      color: "#c12e34",
      symbolSize: 10,
      smooth: true,
      symbol: "roundRect"
    },
    {
      name: '有效量',
      type: 'line',
      data: [],
      color: "#e6b600",
      symbolSize: 10,
      smooth: true,
      symbol: 'rect',
    },
    {
      name: '成交量',
      type: 'bar',
      data: [],
      color: "#0098d9",
      barWidth: 16,
      barGap: 0,
      symbol: "rectangle"
    }
  ]
};
const hkOption = {
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c} ({d}%)'
  },
  series: [
    {
      type: 'pie',
      radius: '50%',
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};
const dealOption = {
  title: {
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c} ({d}%)'
  },
  series: [
    {
      type: 'pie',
      radius: ['20%', '50%'],
      data: [
        { value: 1048, name: 'Search Engine' },
        { value: 735, name: 'Direct' },
        { value: 580, name: 'Email' },
        { value: 484, name: 'Union Ads' },
        { value: 300, name: 'Video Ads' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};
const yxOption = {
  title: {
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c} ({d}%)'
  },
  series: [
    {
      type: 'pie',
      radius: '50%',
      data: [
        { value: 1048, name: 'Search Engine' },
        { value: 735, name: 'Direct' },
        { value: 580, name: 'Email' },
        { value: 484, name: 'Union Ads' },
        { value: 300, name: 'Video Ads' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};
const zhOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    left: 'center',
    bottom: 20,
  },
  // grid: {
  //   left: '3%',
  //   right: '4%',
  //   bottom: '20%',
  //   containLabel: true
  // },
  xAxis: {
    type: 'value',
    // boundaryGap: [0, 0.01],
    axisLine: {
      // x轴
      show: true,
    },
  },
  yAxis: {
    type: 'category',
    data: []
  },
  series: [
    {
      name: '成交量',
      type: 'bar',
      data: [],
      color: "#c12e34",
      barWidth: 16,
      stack: '堆叠'
    },
    {
      name: '客户量',
      type: 'bar',
      // barGap: "-100%",
      data: [],
      color: '#e6b600',
      barWidth: 16,
      stack:'堆叠'
    },
  ]
};
const followOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  xAxis: {
    type: 'category',
    data: ['阿卡丽', 'EZ', '皮城女警', '提莫', '金克斯', '武器大师', '皇子', '德玛西亚', '辛德拉', '发条魔灵'],
    axisLabel: {
      show: true,
      textStyle: {
        color: '#666',
      },
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value}'
    },
    axisPointer: {
      snap: true
    },
    axisLine: {
      // y轴
      show: true,
    },
  },
  legend: {
    left: 'center',
    bottom: 20,
    itemGap: 100,
    data: [
      {
        name: '跟进次数',
        icon: 'rectangle',
        textStyle: {
          color: '',
        },
      },
    ],
  },
  series: [
    {
      name: '跟进次数',
      type: 'bar',
      data: [],
      color: "#0098d9",
      barWidth: 16,
      barGap: 0,
      symbol: "rectangle"
    }
  ]
};
const saleOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    left: 'center',
    bottom: 20,
  },
  grid: {
    left: '14%',
  //   right: '4%',
  //   bottom: '20%',
  //   containLabel: true
  },
  xAxis: {
    type: 'value',
    axisLine: {
      // x轴
      show: true,
    },
  },
  yAxis: {
    type: 'category',
    data: ['酒桶', '寒冰射手', '蛮王之力'],
    axisLabel: {
      margin: 2,
      formatter: function (value) {
          if (value.length > 4) {
              value = value.slice(0, 4) + "..."
          }
          return value;
      }
    },
  },
  series: [
    {
      name: '成交量',
      type: 'bar',
      data: [19, 23, 10],
      color: '#c12e34',
      barWidth: 20
    },
  ]
};
const recordOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    left: 'center',
    bottom: 20,
  },
  xAxis: {
    type: 'value',
    axisLine: {
      // x轴
      show: true,
    },
    minInterval: 1
  },
  grid: {
    left: '14%',
  //   right: '4%',
  //   bottom: '20%',
  //   containLabel: true
  },
  yAxis: {
    type: 'category',
    data: ['冰霜女巫', '放逐之刃'],
    axisLabel: {
      margin: 2,
      formatter: function (value) {
          if (value.length > 4) {
              value = value.slice(0, 4) + "..."
          }
          return value;
      }
    },
  },
  series: [
    {
      name: '跟进记录数',
      type: 'bar',
      data: [19, 23, 10],
      color: '#0098d9',
      barWidth: 20
    },
  ]
};
const callOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    formatter: (params) => {
      let result = [];
      result.push(
        `<p style="margin:0;padding:0;">${params[0].name}</p>`
      );
      for (let i = 0, len = params.length; i < len; i++) {
        if (params[i].componentSubType != 'line') {
          result.push(
            `${params[i].marker}${params[i].seriesName}: ${params[i].value} <br />`
          );
        } else {
          result.push(
            `${params[i].marker}${params[i].seriesName}: ${params[i].value}% <br />`
          );
        }
      }
      return result.join('');
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      show: true,
      textStyle: {
        color: '#666',
      },
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: [
    {
      type: 'value',
      // nameLocation: 'start',
      axisLabel: {
        formatter: '{value}'
      },
      axisPointer: {
        snap: true
      },
      axisLine: {
        // y轴
        show: true,
      },
      minInterval: 1
    },
    {
      type: 'value',
      // nameLocation: 'start',
      nameTextStyle: {
        padding: 10,
      },
      min: 0,
      max: 100,
      interval: 25,
      axisLabel: {
        formatter: '{value} %',
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        //y轴
        show: false,
      },
      position: 'right',
    },
  ],
  legend: {
    left: 'center',
    bottom: 20,
    // itemGap: 100,
    data: [
      {
        name: '拨打数',
        icon: 'rectangle',
        textStyle: {
          color: '',
        },
      },
      {
        name: '接通数',
        icon: 'rectangle',
        textStyle: {
          color: '',
        },
      },
      {
        name: '拨打接通率',
        icon: 'rectangle',
        textStyle: {
          color: '',
        },
      },
    ],
  },
  series: [
    {
      name: '拨打数',
      type: 'bar',
      data: [],
      color: "#c12e34",
      barWidth: 16,
    },
    {
      name: '接通数',
      type: 'bar',
      data: [],
      color: "#e6b600",
      barWidth: 16,
    },
    {
      name: '拨打接通率',
      type: 'line',
      data: [],
      color: "#0098d9",
      yAxisIndex: 1,
    },
  ]
};
const timeOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      show: true,
      textStyle: {
        color: '#666',
      },
    },
    axisTick: {
      show: false,
    },
  },
  grid: {
    left: '14%',
  //   right: '4%',
  //   bottom: '20%',
  //   containLabel: true
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value}'
    },
    axisPointer: {
      snap: true
    },
    axisLine: {
      // y轴
      show: true,
    },
  },
  legend: {
    left: 'center',
    bottom: 20,
    // itemGap: 100,
    data: [
      {
        name: '总通时',
        icon: 'rectangle',
        textStyle: {
          color: '',
        },
      },
      {
        name: '有效通时',
        icon: 'rectangle',
        textStyle: {
          color: '',
        },
      }
    ],
  },
  series: [
    {
      name: '总通时',
      type: 'bar',
      data: [],
      color: "#c12e34",
      barWidth: 16,
      symbol: "rectangle"
    },
    {
      name: '有效通时',
      type: 'bar',
      data: [],
      color: "#2b821d",
      barWidth: 16,
      symbol: "rectangle"
    },
  ]
};
export default {
  components: {
    Search,
    ModalTable: () => import('./components/modal-table.vue')
  },
  data() {
    return {
      searchParam: {},
      searchAllParam: [
        {
          name: "deptId",
          label: "校区",
          type: "select",
          option: [],
          value: "",
          text: "deptName",
          id: "deptId",
        },
        {
          name: "data",
          label: "跟进日期",
          type: "month",
          ref: "follow",
          show: false,
          value: new Date().toISOString().substr(0, 7),
        },
      ],
      searchParamList: [],
      // 当月新量核心指标
      targetList: [
        {name: '分配量', value: 0, color: '#c12e34'},
        {name: '有效量', value: 0, color: '#e6b600'},
        {name: '成交量', value: 0, color: '#0098d9'},
        {name: '无效量', value: 0, color: '#2b821d'}
      ],
      courseChart: null,
      columns: [],
      // 当月客户配量分析详情表格
      analyseTable: [
        {label: '校区名称', prop: 'deptName'},
        {label: '客户编号', prop: 'customerId'},
        {label: '姓名', prop: 'name'},
        {label: '客户状态', prop: 'statusName'},
        {label: '创建日期', prop: 'createTime'},
        {label: '来源', prop: 'sourceName'},
        {label: '渠道名称', prop: 'recruitChannelsName'},
        {label: '归属老师', prop: 'userName'},
        {label: '意向状态', prop: 'followStatusName'}
      ],
      // 获客渠道占比分析详情表格
      hkTable: [
        {label: '校区名称', prop: 'deptName'},
        {label: '商机编号', prop: 'customerId'},
        {label: '姓名', prop: 'name'},
        {label: '商机状态', prop: 'statusName'},
        {label: '创建日期', prop: 'createTime'},
        {label: '来源', prop: 'sourceName'},
        {label: '渠道名称', prop: 'recruitChannelsName'},
        {label: '意向状态', prop: 'followStatusName'}
      ],
      // 各渠道转化占比分析详情表格
      zhTable: [
        {label: '校区属性', prop: 'schoolTypeName'},
        {label: '客户编号', prop: 'customerId'},
        {label: '姓名', prop: 'name'},
        {label: '创建日期', prop: 'createTime'},
        {label: '来源', prop: 'sourceName'},
        {label: '渠道名称', prop: 'recruitChannelsName'},
        {label: '意向状态', prop: 'followStatusName'}
      ],
      // 销售-成交客户分析详情表格
      saleTable: [
        {label: '客户编号', prop: 'customerId'},
        {label: '校区名称', prop: 'deptName'},
        {label: '商机状态', prop: 'statusName'},
        {label: '渠道名称', prop: 'recruitChannelsName'},
        {label: '归属销售', prop: 'userName'},
        {label: '意向状态', prop: 'followStatusName'},
        {label: '最新跟进时间', prop: 'createTime'},
      ],
      loading: false,
    };
  },
  mounted() {
    this.searchParamList = this.searchAllParam;
    this.getQuerySchoolList()
  },
  methods: {
    async getQuerySchoolList() {
      const { code, result} = await getQuerySchoolList()
      if(code === 0) {
        this.searchAllParam.map(item => {
          if(item.name === "deptId") {
            item.option = result
            item.value = result[0].deptId
          }
        })
      }
      this.search()
    },
    async getQuerySchoolFollowReport() {
      this.loading = true
      const { code, result } = await getQuerySchoolFollowReport({...this.searchParam})
      if(code === 0) {
        // 当月新量核心指标
        this.initTarget(result.coreIndicatorsEntity)
        // 当月客户配量分析
        this.initAnalyse(result.customerRationAnalysisEntity)
        // 获客渠道占比分析
        this.initHk(result.customerChannelEntity)
        // 成交客户-渠道分布占比分析
        this.initDeal(result.customerDealChannelEntity)
        // 未成交客户-意向状态分析
        this.initYx(result.customerIntentionalStateEntity)
        // 各渠道转化占比分析
        this.initZh(result.channelTransformationEntity)
        // 成交客户跟进次数分析
        this.initFollow(result.customerDealFollowEntity)
        // 销售-成交客户分析
        this.initSale(result.customerDealEntity)
        // 跟进记录统计
        this.initRecord(result.customerFollowRecordEntity)
        // 外呼统计分析
        this.initCall(result.outboundCallEntity)
        // 通时统计
        this.initTime(result.callTimeEntity)
      } else {
        // 当月新量核心指标
        this.initTarget()
        // 当月客户配量分析
        this.initAnalyse()
        // 获客渠道占比分析
        this.initHk()
        // 成交客户-渠道分布占比分析
        this.initDeal()
        // 未成交客户-意向状态分析
        this.initYx()
        // 各渠道转化占比分析
        this.initZh()
        // 成交客户跟进次数分析
        this.initFollow()
        // 销售-成交客户分析
        this.initSale()
        // 跟进记录统计
        this.initRecord()
        // 外呼统计分析
        this.initCall()
        // 通时统计
        this.initTime()
      }
      this.loading = false
    },
    search() {
      this.searchParam = {
        deptId: '',
        data: []
      }
      this.searchAllParam.map(item => {
        if(item.value) {
          this.searchParam[item.name] = item.value
        }
      })
      this.getQuerySchoolFollowReport()
    },
    reset() {
      this.searchAllParam.map(item => {
        if(item.value) {
          this.searchParam[item.name] = ""
          item.value = ""
        }
      })
      this.getQuerySchoolFollowReport()
    },
    // 初始化当月新量核心指标
    initTarget(obj = {}) {
      obj = obj == null?{}:obj
      this.targetList.map(item => {
        switch (item.name) {
          case '分配量':
            item.value = obj.distributionNum || 0
            break;
          case '有效量':
            item.value = obj.effectiveNum || 0
            break;
          case '成交量':
            item.value = obj.dealNum || 0
            break;
          case '无效量':
            item.value = obj.invalidNum || 0
            break
          default:
            break;
        }
      })
    },
    // 初始化当月客户配量分析
    initAnalyse(dep = []) {
      dep = dep == null?[]:dep
      option.xAxis.data = []
      option.series[0].data = []
      option.series[1].data = []
      option.series[2].data = []
      dep.length && dep.map(item => {
        option.xAxis.data.push(item.date)
        option.series[0].data.push(item.customerNum)
        option.series[1].data.push(item.effectiveNum)
        option.series[2].data.push(item.dealNum)
      })
      const myChart = echarts.init(this.$refs['analyseChart']);
      option && myChart.setOption(option);
      myChart.off('click')
      myChart.on('click', params => {
        // if(params.componentSubType === 'line') {
          this.columns = this.analyseTable
          this.$refs.table.getDetail({coreData: params.name, type: 0})
        // }
      })
    },
    // 获客渠道占比分析
    initHk(dep = []) {
      dep = dep == null?[]:dep
      dep.length && dep.map(item => {
        this.$set(item, 'name', item.channelName)
        this.$set(item, 'value', item.channelNum)
      })
      hkOption.series[0].data = dep
      const hkChart = echarts.init(this.$refs['hkChart']);
      hkOption && hkChart.setOption(hkOption);
      hkChart.off("click")
      hkChart.on('click', params => {
        this.columns = this.hkTable
        this.$refs.table.getDetail({channelId: params.data.channelId, type: 1})
      })
    },
    // 成交客户-渠道分布占比分析
    initDeal(dep = []) {
      dep = dep == null?[]:dep
      dep.length && dep.map(item => {
        this.$set(item, 'name', item.channelName)
        this.$set(item, 'value', item.channelNum)
      })
      dealOption.series[0].data = dep
      const dealChart = echarts.init(this.$refs['dealChart']);
      dealOption && dealChart.setOption(dealOption);
      dealChart.off('click')
      dealChart.on('click', params => {
        this.columns = this.hkTable
        this.$refs.table.getDetail({channelId: params.data.channelId, type: 2})
      })
    },
    // 未成交客户-意向状态分析
    initYx(dep = []) {
      dep = dep == null?[]:dep
      dep.length && dep.map(item => {
        this.$set(item, 'name', item.followStatusName)
        this.$set(item, 'value', item.followStatusNum)
      })
      yxOption.series[0].data = dep
      const yxChart = echarts.init(this.$refs['yxChart']);
      yxOption && yxChart.setOption(yxOption);
      yxChart.off('click')
      yxChart.on('click', params => {
        this.columns = this.hkTable
        this.$refs.table.getDetail({followStatus: params.data.followStatus, type: 3})
      })
    },
    // 各渠道转化占比分析
    initZh(dep = []) {
      dep = dep == null?[]:dep
      zhOption.yAxis.data = []
      zhOption.series[1].data = []
      zhOption.series[0].data = []
      if(dep.length > 9) {
        zhOption.series[0].barWidth = 10
        zhOption.series[1].barWidth = 10
      } else {
        zhOption.series[0].barWidth = 16
        zhOption.series[1].barWidth = 16
      }
      dep.length && dep.map(item => {
        zhOption.yAxis.data.push(item.channelName)
        zhOption.series[0].data.push(item.dealNum)
        zhOption.series[1].data.push(item.customerNum)
      })
      const zhChart = echarts.init(this.$refs['zhChart']);
      zhOption && zhChart.setOption(zhOption);
      zhChart.off('click')
      zhChart.on('click', params => {
        this.columns = this.zhTable
        let channelId = ""
        dep.map(item => {
          if(params.name == item.channelName) {
            channelId = item.channelId
          }
        })
        this.$refs.table.getDetail({channelId, type: 1})
      })
    },
    // 成交客户跟进次数分析
    initFollow(dep = []) {
      dep = dep == null?[]:dep
      followOption.xAxis.data = []
      followOption.series[0].data = []
      dep.length && dep.map(item => {
        followOption.xAxis.data.push(item.name)
        followOption.series[0].data.push(item.followNumber)
      })
      const followChart = echarts.init(this.$refs['followChart']);
      followOption && followChart.setOption(followOption);
    },
    // 销售-成交客户分析
    initSale(dep = []) {
      dep = dep == null?[]:dep
      saleOption.yAxis.data = []
      saleOption.series[0].data = []
      dep.length && dep.map(item => {
        saleOption.yAxis.data.push(item.userName)
        saleOption.series[0].data.push(item.dealNum)
      })
      const saleChart = echarts.init(this.$refs['saleChart']);
      saleOption && saleChart.setOption(saleOption);
      saleChart.off('click')
      saleChart.on('click', params => {
        this.columns = this.saleTable
        let userId = ""
        dep.map(item => {
          if(item.userName === params.name) {
            userId = item.userId
          }
          saleOption.yAxis.data.push(item.userName)
          saleOption.series[0].data.push(item.dealNum)
        })
        this.$refs.table.getDetail({userId, type: 4})
      })
    },
    // 跟进记录统计
    initRecord(dep = []) {
      dep = dep == null?[]:dep
      recordOption.yAxis.data = []
      recordOption.series[0].data = []
      dep.length && dep.map(item => {
        recordOption.yAxis.data.push(item.userName)
        recordOption.series[0].data.push(item.followNumber)
      })
      const recordChart = echarts.init(this.$refs['recordChart']);
      recordOption && recordChart.setOption(recordOption);
    },
    // 外呼统计分析
    initCall(dep = []) {
      dep = dep == null?[]:dep
      callOption.xAxis.data = []
      callOption.series[0].data = []
      callOption.series[1].data = []
      callOption.series[2].data = []
      let lineData = []
      dep.length && dep.map(item => {
        callOption.xAxis.data.push(item.userName)
        callOption.series[0].data.push(item.dialNum)
        callOption.series[1].data.push(item.connectNum)
        lineData.push(Number(item.dialPercent.replace('%', '')))
      })
      callOption.series[2].data = lineData
      const callChart = echarts.init(this.$refs['callChart']);
      callOption && callChart.setOption(callOption);
    },
    // 通时统计
    initTime(dep = []) {
      dep = dep == null?[]:dep
      timeOption.xAxis.data = []
      timeOption.series[0].data = []
      timeOption.series[1].data = []
      dep.length && dep.map(item => {
        timeOption.xAxis.data.push(item.userName)
        timeOption.series[0].data.push(item.totalCallTime)
        timeOption.series[1].data.push(item.effectiveCallTime)
      })
      const timeChart = echarts.init(this.$refs['timeChart']);
      timeOption && timeChart.setOption(timeOption);
    }
  }
};
</script>
<style lang="scss" scoped>
  .school-follow {
    .target {
      >.el-row {
        margin-top: 10px;
        .el-col {
          height: 90px;
          text-align: center;
          background: red;

          h4 {
            color: #fff;
            font-size: 32px;
            margin-top: 10px;
          }
          span {
            display: block;
            color: #fff;
          }
        }
      }
    }
  }
</style>
